import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Container from "@components/container"
import Layout from "@components/layout"
import StaticHero from "@components/staticHero"
import { Heading2, smallBeforeGradient, sectionMd } from "@styles/ui"
import KeyPoints from "@components/keyPoints"
import Seo from "@components/seo"
import Callout from "@components/narcolepsy/CallOut"

// Assets
import adult from "@images/xywav-side-effects/adult.svg"
import child from "@images/xywav-side-effects/icon-child.svg"
import logo from "@images/logos/xywav-logo-small.svg"

const keyPoints = [
  {
    text: (
      <div tw="text-left text-sm leading-[1.4] xl:(text-xl)">
        <h3 tw="font-black mb-2.5 xl:(mb-3.5)">Breathing problems</h3>
        <p tw="mb-3">
          Including slower breathing, trouble breathing, and/or short periods of
          not breathing while sleeping (sleep apnea).
        </p>
        <p>
          People who already have breathing or lung problems have a higher
          chance of having breathing problems when they use XYWAV.
        </p>
      </div>
    ),
  },
  {
    text: (
      <div tw="text-left text-sm leading-[1.4] xl:(text-xl)">
        <h3 tw="font-black mb-2.5 xl:(mb-3.5)">Mental health problems</h3>
        <p tw="mb-3">
          Including confusion, seeing or hearing things that are not real
          (hallucinations), unusual or disturbing thoughts (abnormal thinking),
          feeling anxious or upset, depression, thoughts of killing yourself or
          trying to kill yourself, increased tiredness, feelings of guilt or
          worthlessness, or difficulty concentrating.
        </p>
        <p>
          Tell your doctor if you or your child have or had depression or have
          tried to harm yourself or themselves.{" "}
          <strong>
            Call your doctor right away if you have or your child has symptoms
            of mental health problems or a change in weight or appetite.
          </strong>
        </p>
      </div>
    ),
  },
  {
    text: (
      <div tw="text-left text-sm leading-[1.4] xl:(text-xl)">
        <h3 tw="font-black mb-2.5 xl:(mb-3.5)">Sleepwalking</h3>
        <p>
          XYWAV can cause sleepwalking, which can cause injuries. Call your
          doctor if this occurs.
        </p>
      </div>
    ),
  },
]

const sideEffectsAdult = {
  col1: [
    {
      text: "Headache",
    },
    {
      text: "Nausea",
    },
    {
      text: "Dizziness",
    },
    {
      text: "Decreased appetite",
    },
    {
      text: "Parasomnia",
    },
  ],
  col2: [
    {
      text: "Diarrhea",
    },
    {
      text: "Excessive sweating",
    },
    {
      text: "Anxiety",
    },
    {
      text: "Vomiting",
    },
  ],
}

const sideEffectsChild = {
  col1: [
    {
      text: "Nausea",
    },
    {
      text: "Bedwetting",
    },
    {
      text: "Vomiting",
    },
    {
      text: "Headache",
    },
  ],
  col2: [
    {
      text: "Weight decrease",
    },
    {
      text: "Decreased appetite",
    },
    {
      text: "Dizziness",
    },
    {
      text: "Sleepwalking",
    },
  ],
}

const listStyles = tw`[padding-inline-start: 20px] list-disc`
const listItemStyles = tw`mb-1 mr-3 pr-1`

const XywavSideEffectsPage = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Safety & Side Effects | XYWAV®"
        description="Understand the possible side effects of XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution for your treatment of 2 narcolepsy symptoms. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      {/* Static hero */}
      <StaticHero
        eyebrow="Side effects"
        imageData={data.heroImage}
        gradient="orange"
      >
        <span tw="xl:(block mr-[-122px])">
          Understanding the common side effects of treatment with XYWAV
        </span>
      </StaticHero>

      {/* Keypoints */}
      <section css={[sectionMd, tw`bg-seashell relative`]}>
        <img
          src={logo}
          alt=""
          tw="hidden lg:(block absolute -left-32 bottom-0 opacity-20)"
          width="410px"
        />
        <Container>
          <h2 css={[Heading2, tw`text-center mb-[25px] md:(mb-7 mx-auto)`]}>
            XYWAV can cause serious side effects, including the following:
          </h2>
          <KeyPoints points={keyPoints} />
        </Container>
      </section>

      {/* Adult side effects */}
      <section tw="mt-4 mb-[50px]">
        <Container>
          <div tw="md:(flex items-center justify-between)">
            <div tw="mb-8 md:(flex-1 mb-0)">
              <img
                src={adult}
                tw=" mx-auto max-w-[255px] md:(max-w-[470px] mx-[unset])"
                alt=""
              />
            </div>
            <div tw="md:(flex-1 text-3xl)">
              <h2 css={[Heading2, tw`mb-5 xl:mb-7`]}>
                For adults, the most common side effects include:
              </h2>

              <div tw="flex text-sm gap-x-6 md:(gap-x-[105px] text-xl)">
                <ul css={listStyles}>
                  {sideEffectsAdult["col1"].map((item, i) => {
                    return (
                      <li key={i} css={listItemStyles}>
                        {item.text}
                      </li>
                    )
                  })}
                </ul>
                <ul css={listStyles}>
                  {sideEffectsAdult["col2"].map((item, i) => {
                    return (
                      <li key={i} css={listItemStyles}>
                        {item.text}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div
            css={[
              smallBeforeGradient,
              tw`mb-6 mx-3 text-center before:(block mb-6 h-1.5 rounded-md gradientRed mx-auto w-[100px] lg:w-[124px])`,
              tw`text-center mt-12 xl:(mt-[65px])`,
            ]}
          >
            <p tw="mb-3 font-black xl:mb-5">
              In patients who remained on treatment, side effects tended to
              occur early and diminish over time
            </p>
            <p tw="md:mx-auto">
              Overall, adverse reactions related to XYWAV were reported less
              frequently in patients who were taking XYREM<sup>&reg;</sup>{" "}
              (sodium oxybate) oral solution when they entered the study,
              compared to patients who weren&apos;t taking XYREM.
            </p>
          </div>
        </Container>
      </section>

      {/* Pediatric side effects */}
      <section>
        <Container>
          <div tw="flex flex-col-reverse md:(flex-row flex items-center justify-between)">
            <div tw="md:(flex-1  text-3xl)">
              <h2 css={[Heading2, tw`mb-5 md:max-w-[570px]`]}>
                For children, the most common side effects* include:
              </h2>

              <div tw="text-sm xl:text-xl">
                <div tw="flex gap-x-6 md:gap-x-[180px]">
                  <ul css={listStyles}>
                    {sideEffectsChild["col1"].map((item, i) => {
                      return (
                        <li key={i} css={listItemStyles}>
                          {item.text}
                        </li>
                      )
                    })}
                  </ul>
                  <ul css={listStyles}>
                    {sideEffectsChild["col2"].map((item, i) => {
                      return (
                        <li key={i} css={listItemStyles}>
                          {item.text}
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <p tw="mt-6">*Of XYREM (which contains oxybate like XYWAV).</p>
              </div>
            </div>
            <div tw="mb-8 md:(flex-1 mb-0)">
              <img
                src={child}
                tw=" mx-auto max-w-[255px] md:(max-w-[470px] mr-[unset])"
                alt=""
              />
            </div>
          </div>
        </Container>
      </section>

      {/* Other side effects */}
      <section css={[sectionMd]}>
        <Container grid>
          <div tw="col-span-6">
            <h2
              css={[
                Heading2,
                tw`mb-7.5`,
                tw`mb-6 mx-3 text-center before:(block mb-6 h-1.5 rounded-md gradientRed mx-auto w-[100px] lg:w-[124px])`,
              ]}
            >
              There are other possible side effects of XYWAV
            </h2>
          </div>
          <div css={[tw`text-center md:(text-left col-start-8 col-span-5)`]}>
            <p>
              For more information, ask your doctor or pharmacist. Call your
              doctor for medical advice about side effects. You are encouraged
              to report any negative side effects to the FDA at&nbsp;
              <a href="tel:18003321088" tw="underline text-studio">
                <nobr>1-800-FDA-1088</nobr>
              </a>
              .
            </p>
          </div>
        </Container>
      </section>

      {/* Orange callout */}
      <Callout
        theme="orange"
        cta={{
          text: "Watch Getting Started Videos",
          url: "/narcolepsy/getting-xywav/#jazzcares-videos",
        }}
      >
        <h2 css={[Heading2, tw`text-white mb-[25px]`]}>
          Already prescribed XYWAV?
        </h2>
        <p>
          Watch a JazzCares
          <sup tw="text-[9px] align-top leading-[2.3]">&reg;</sup> Nurse Case
          Manager discuss the services available to support you on your journey,
          including what to expect when getting&nbsp;started.
        </p>
      </Callout>
    </Layout>
  )
}

export default XywavSideEffectsPage

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "xywav-side-effects/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800, placeholder: NONE)
      }
    }
  }
`
